<template>
  <div class="c-modal-backdrop">
    <img
      class="intro-players animate__animated animate__fadeInUp animate__slow"
      v-bind:src="require('@/assets/img/min/modal-players.png')"
    />
    <div
      class="c-modal-outer"
      role="dialog"
      aria-labelledby="modalTitle"
      aria-describedby="modalDescription"
    >
      <section class="c-modal-body" id="modalDescription">
        <div class="intro-container">
          <svg
            id="xBtn"
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="39"
            viewBox="0 0 39 39"
            v-on:click="close()"
          >
            <g
              id="Group_1418"
              data-name="Group 1418"
              transform="translate(-450 -130)"
            >
              <g id="Group_4233" data-name="Group 4233">
                <circle
                  id="Ellipse_36"
                  data-name="Ellipse 36"
                  cx="19.5"
                  cy="19.5"
                  r="19.5"
                  transform="translate(450 130)"
                  fill="#fec221"
                />
                <g
                  id="Group_1028"
                  data-name="Group 1028"
                  transform="translate(462.887 142.853)"
                >
                  <line
                    id="Line_5"
                    data-name="Line 5"
                    x2="13.466"
                    y2="13.423"
                    fill="none"
                    stroke="#172954"
                    stroke-width="3"
                  />
                  <line
                    id="Line_6"
                    data-name="Line 6"
                    x1="13.466"
                    y2="13.423"
                    fill="none"
                    stroke="#172954"
                    stroke-width="3"
                  />
                </g>
              </g>
            </g>
          </svg>

          <h2>POSTANI LEGENDARNO</h2>
          <h1>IME ZLATOROGA</h1>
          <br />
          <p>
            <b
              >V 16 nepozabnih tekmovalnih sezonah smo v Zlatorogu našteli več
              kot 650.000 navijačev. Energija, ki jo podporniki dajete klubu, je
              brezmejna. Zato vas v času, ko se moramo držati omejitev, vabimo,
              da svojo brezmejno pripadnost klubu še enkrat več ponosno
              pokažete.</b
            >
          </p>
          <br />
          <p>
            Čeprav vas nekaj časa v Zlatorogu ne bomo videli v polnem številu,
            to še ne pomeni, da Zlatorog ne bo poln. Podprite nas in zapišite
            svoje ime ali ime vašega podjetja na sedež v dvorani in s to
            simbolno gesto, ki ne presega vrednosti letne vstopnice, podprite
            vse selekcije celjske rokometne družine.
          </p>
          <br />
          <b-button
            variant="yellow"
            pill
            v-on:click="close()"
            class="hoverBlue modal-btn"
            >ZAKUPI SVOJ SEDEŽ</b-button
          >
          <br />
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "modal",

  data() {
    return {
      show: true,
    };
  },

  methods: {
    close() {
      this.$emit("close");
    },
  },

  mounted() {
    window.addEventListener("keyup", (event) => {
      if (event.keyCode === 27) {
        this.close();
      }
    });
  },
};
</script>

<style scoped lang="scss">
h1 {
  font-family: $barlowBold;
  font-size: 2.6em;
  text-transform: uppercase;

  @media screen and (min-width: 500px) {
    font-size: 3.8em;
  }

  @media screen and (min-width: 600px) {
    font-size: 5.5em;
  }
}

h2 {
  font-family: $barlowBold;
  font-size: 2em;
  text-transform: uppercase;
  margin: 0.5em 0 0 0;

  @media screen and (min-width: 400px) {
    margin: 4rem 0 0 0;
  }

  @media screen and (min-width: 500px) {
    font-size: 2.4em;
  }

  @media screen and (min-width: 600px) {
    font-size: 3em;
  }
}

p {
  font-family: $font-family;
  font-size: 1.25em;
  line-height: 1.5;
}

b {
  font-family: $sourceBold;
}

#xBtn {
  display: none;
  max-width: 40px;
  cursor: pointer;

  &:hover {
    #Ellipse_36 {
      fill: $darkBlue;
      transition: all ease 0.2s;
    }
    #Line_5,
    #Line_6 {
      stroke: white;
      transition: all ease 0.2s;
    }
  }

  @media screen and (min-width: 550px) {
    display: block;
  }
}

.c-modal-outer {
  position: relative;
  overflow-x: auto;
  width: 100vw;
  background: white;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;

  @media screen and (min-width: 550px) {
    background: url("~@/assets/img/mobile-modal-bg.png");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media screen and (min-width: 1400px) {
    background: url("~@/assets/img/modal-md-bg.png");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
  }

  @media screen and (min-width: $xxl) {
    background: url("~@/assets/img/modal-bg.png");
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
  }
}

.c-modal-body {
  position: relative;
  padding: 0 0 0 16vw;
  width: 80vw;
  display: flex;
  align-items: center;
  height: 100vh;

  @media screen and (max-width: $xxl) {
    padding: 0 0 0 10vw;
    width: 90vw;
  }
}

.intro-container {
  position: relative;
  max-width: 640px;

  @media screen and (max-width: $mobile) {
    padding: 0 50px 0 0;
  }

  @media screen and (max-width: 500px) {
    position: absolute;
    top: 10%;
    left: 10%;
    width: 90%;
    padding: 0;
    margin-bottom: 20%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }

  p {
    @media screen and (max-width: 500px) {
      line-height: 1.2;
    }
  }

  .modal-btn {
    margin-top: 60px;
    margin-bottom: 20px;

    @media screen and (max-width: 500px) {
      margin-top: 0px;
      font-size: 1rem;
    }
  }
}

.intro-players {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 50vw;
  width: auto;
  z-index: 1;
  max-height: 100vh;

  @media screen and (max-width: 1100px) {
    display: none;
  }

  @media screen and (max-width: 1400px) {
    max-width: 40vw;
  }
}

.x-btn {
  cursor: pointer;
}

.facts {
  p {
    line-height: 1.1em;
  }

  h4 {
    font-family: $barlowMedium;
    color: $yellow;
    font-size: 3.2em;
    text-transform: uppercase;
  }

  .odpoved {
    font-family: $barlowBold;
    font-size: 2.4em;
    line-height: 0.85em;
  }

  .smaller {
    font-family: $barlowBold;
    font-size: 1.4em;
  }
}

.c-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  z-index: 11;

  @media screen and (min-width: 400px) {
    font-size: 14px;
  }

  @media screen and (min-width: $mobile) {
    font-size: 16px;
  }
}

.btn-yellow {
  font-size: 1.8rem;

  @media screen and (max-width: $mobile) {
    padding: 0.4rem 4rem;
    font-size: 1.3rem;
  }

  @media screen and (max-width: 400px) {
    padding: 0.4rem 2rem;
  }
}
</style>