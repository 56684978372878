  
<template>
  <div class="side-list">
    <ul id="menu">
      <li data-menuanchor="sec1" class="active">
        <a href="#sec1">
          <span class="dot"></span> PRIDRUŽI SE NAM
        </a>
      </li>
      <br />
      <li data-menuanchor="sec2">
        <a href="#sec2">
          <span class="dot"></span> ZAKUPI SVOJ SEDEŽ
        </a>
      </li>
      <br />
      <li data-menuanchor="sec3">
        <a href="#sec3">
          <span class="dot"></span> PODPRI NAS
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "list"
};
</script>

<style lang="scss">
.side-list {
  position: fixed;
  left: 2%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;

  #menu {
    position: relative;
    padding: 0;
    margin: 0;

    @media screen and (max-width: 960px) {
      display: none;
    }

    li {
      a {
        color: white !important;
        font-family: $barlowMedium;
        line-height: 1em;
        white-space: nowrap;
        font-size: 1.2em;

        &:hover {
          text-decoration: none !important;
        }

        .dot {
          height: 0.9em;
          width: 0.9em;
          border: 1px solid white;
          border-radius: 50%;
          display: inline-block;
          margin-right: 1em;
        }
      }
    }
    .active {
      font-weight: bold;
      color: $yellow !important;

      a:after {
        display: none;
      }

      .dot {
        border: 1px solid $yellow;
        background-color: $yellow;
      }
    }
  }
}
</style>