  
<template>
  <div class="socials-container">
    <a v-bind:href="'https://www.facebook.com/rkceljepivovarnalasko'">
      <img class="icon" v-bind:src="require('@/assets/img/fb.svg')" alt />
    </a>
    <br />
    <a v-bind:href="'https://www.instagram.com/rkcpl/'">
      <img class="icon" v-bind:src="require('@/assets/img/insta.svg')" alt />
    </a>
    <br />
    <br />
    <br />
    <img v-bind:src="require('@/assets/img/scroll.svg')" alt />
  </div>
</template>

<script>
export default {
  name: "socials"
};
</script>

<style scoped lang="scss">
.socials-container {
  display: none;

  @media screen and (min-width: 530px) {
    display: block;
    position: fixed;
    left: 0;
    bottom: 0;
    text-align: center;
    width: 100px;
    padding: 20px;
    z-index: 5;

    .icon {
      padding: 10px 0;
      transform: scale(1.3);
    }
  }
}
</style>