  
<template>
  <div class="top-logo-container">
    <img class="top-logo" v-bind:src="require('@/assets/img/logo.svg')" />
    <b-btn pill variant="small" id="top-left-btn" class="hoverWhite">
      <a class="blue-txt" v-bind:href="'https://shop.rk-celje.si'">TRGOVINA</a>
    </b-btn>
  </div>
</template>

<script>
export default {
  name: "headerTop"
};
</script>

<style scoped lang="scss">
.top-logo-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  text-align: center;
  z-index: 10;

  .top-logo {
    position: relative;
    max-width: 40px;
    width: 20%;
    margin: 2vh auto;

    @media screen and (min-width: $mobile) {
      max-width: 70px;
    }
  }

  #top-left-btn {
    position: absolute;
    top: 20px;
    right: 20px;

    @media screen and (min-width: $mobile) {
      top: 40px;
      right: 50px;
    }
  }
}
</style>