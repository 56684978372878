  
<template>
  <div class="scrollicon-container">
    <svg
      class="scroll-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20.479"
      height="34.052"
      viewBox="0 0 20.479 34.052"
    >
      <g id="Group_1363" data-name="Group 1363" transform="translate(-949.761 -962.19)">
        <g id="Group_1149" data-name="Group 1149" transform="translate(1272.097 717.658)">
          <rect
            id="Rectangle_172"
            data-name="Rectangle 172"
            width="17.875"
            height="31.7"
            rx="8.937"
            transform="translate(-321.318 245.872) rotate(-1.033)"
            fill="none"
            stroke="#fafafa"
            stroke-miterlimit="10"
            stroke-width="2"
          />
          <line
            id="Line_8"
            data-name="Line 8"
            x2="0.097"
            y2="5.397"
            transform="translate(-312.312 252.095)"
            fill="none"
            stroke="#fafafa"
            stroke-linecap="round"
            stroke-miterlimit="10"
            stroke-width="2"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: "scrollIcon"
};
</script>

<style scoped lang="scss">
.scrollicon-container {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding-bottom: 4vh;

  .scroll-icon {
    margin: auto;
  }
}
</style>