<template>
  <div class="home">
    <modal v-show="isModalVisible" @close="closeModal" />

    <headerTop />
    <list />
    <socials />

    <full-page :options="options" id="fullpage" ref="fullpage">
      <div class="section">
        <section1
          :isModalVisible="isModalVisible"
          @scrollDown="$refs.fullpage.api.moveSectionDown()"
        />
      </div>

      <div class="section">
        <section2
          @inputData="updateName"
          @scrollDown="$refs.fullpage.api.moveSectionDown()"
          @scrollUp="$refs.fullpage.api.moveSectionUp()"
        />
      </div>

      <div class="section">
        <section3 :name="name" />
      </div>
    </full-page>
  </div>
</template>

<script>
import modal from "@/sections/modal";
import section1 from "@/sections/section1";
import section2 from "@/sections/section2";
import section3 from "@/sections/section3";
import headerTop from "@/components/header";
import list from "@/components/list";
import socials from "@/components/socials";

export default {
  name: "Home",

  components: {
    modal,
    section1,
    section2,
    section3,
    headerTop,
    list,
    socials
  },

  data: function() {
    return {
      isModalVisible: true,
      name: "",
      options: {
        licenseKey: "37785B51-A8FD407C-8992A089-F1ABAA7F",
        afterLoad: this.afterLoad,
        navigation: true,
        menu: "#menu",
        anchors: ["sec1", "sec2", "sec3"],
        scrollOverflow: true
      }
    };
  },

  methods: {
    showModal() {
      this.isModalVisible = true;
    },
    closeModal() {
      this.isModalVisible = false;
    },

    updateName(variable) {
      this.name = variable;
    }
  },

  mounted() {
    document.title = "Zakupi sedež";
    this.showModal();
    this.$refs.fullpage.api.setAllowScrolling(false);
  },

  watch: {
    isModalVisible: function() {
      if (this.isModalVisible) {
        this.$refs.fullpage.api.setAllowScrolling(false);
      } else {
        this.$refs.fullpage.api.setAllowScrolling(true);
      }
    }
  }
};
</script>

<style>
/* Menu
 * --------------------------------------- */
#menu-line {
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 159px;
  height: 2px;
  background: #fff;
}

#menu {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 70;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  letter-spacing: 1px;
  font-size: 1.1em;
}
#menu li {
  display: inline-block;
  margin: 10px 0px;
  position: relative;
}
#menu a {
  color: #fff;
  padding: 0 1.1em 1.1em 1.1em;
}
#menu li.active a:after {
  content: "";
  margin: 0 1.1em 0 1.1em;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  display: block;
}

/* Actions buttons
 * --------------------------------------- */
.actions {
  position: fixed;
  bottom: 2%;
  margin: 0 auto;
  z-index: 99;
  left: 0;
  right: 0;
  text-align: center;
}
.actions li {
  display: inline-block;
  margin: 0.3em 0.3em;
}
.actions-button {
  padding: 0.73em 1.47em;
  background: rgba(53, 73, 94, 0.47);
  border-radius: 5px;
  display: block;
  color: #fff;
  cursor: pointer;
}
</style>