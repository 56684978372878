  
<template>
  <b-container class="vpisi text-center" fluid>
    <img class="player3" v-bind:src="require('@/assets/img/min/player3@2x-min.png')" />

    <div class="custom-container">
      <div class="mobile-nobreak">
        <div class="input-box">
          <div class="visible-field">
            <input
              id="vnesiIme"
              class="custom-input"
              type="text"
              v-model="name2"
              placeholder="IME PRIIMEK"
            />
            <img class="line" v-bind:src="require('@/assets/img/line.svg')" alt />
            <p class="small">Vpiši svoje ime in priimek oz. naziv podjetja ali organizacije.</p>
            <b-btn v-on:click="potrdi()" pill class="hoverBlue" variant="yellow">POTRDI</b-btn>
          </div>
          <div class="opozorilo-container">
            <p
              class="small opozorilo"
            >Opozorilo: pridržujemo si pravico do zavrnitve predloga zapisa v primeru sporne vsebine.</p>
          </div>
        </div>
        <div class="chair-container">
          <div class="txt-container">
            <img class="stars" v-bind:src="require('@/assets/img/stars.svg')" />
            <div class="name-container">
              <p>{{ name2 }}</p>
            </div>
            <img class="mastercard" v-bind:src="require('@/assets/img/mastercard.svg')" alt />
          </div>
          <img class="chair" v-bind:src="require('@/assets/img/min/chair3@2x-min.png')" alt />
        </div>
      </div>
    </div>

    <msgBox
      text="Povežimo se! Vpiši svoje ime in bodi del rokometnega hrama RK Celje Pivovarna Laško skozi celotno sezono."
      title="ZAKUPI SVOJ SEDEŽ"
      @scrollDown="$emit('scrollDown');"
      @scrollUp="$emit('scrollUp');"
    ></msgBox>

    <scrollIcon></scrollIcon>
  </b-container>
</template>

<script>
import msgBox from "@/components/msgBox";
import scrollIcon from "@/components/scrollIcon";

export default {
  name: "vpisi",

  components: {
    msgBox,
    scrollIcon
  },

  data() {
    return {
      name2: ""
    };
  },

  methods: {
    potrdi() {
      this.$emit("inputData", this.name2.toUpperCase());
      this.$emit("scrollDown");
    }
  },

  watch: {
    name2: function() {
      this.$emit("inputData", this.name2.toUpperCase());
    }
  }
};
</script>

<style scoped lang="scss">
.vpisi {
  position: relative;
  background: $bg1;
  background-size: cover;
  background-position: center;
  height: 100vh;
  max-height: 100vh;
  font-size: 14px;
  min-height: 640px;

  @media screen and (min-width: $mobile) {
    font-size: 16px;
  }
}

.msg-box {
  display: none;

  @media screen and (min-width: 1200px) {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 350px;

    .txt-container {
      background-color: white;
      margin: 0;
      padding: 25px 35px;
      border-top-left-radius: 23px;
      text-align: left;

      p {
        color: $darkBlue;
        font-family: $font-family;
        font-size: 0.9em;
        padding: 0;
        margin: 0;
      }
    }

    .blue-container {
      background-color: $darkBlue;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 35px;

      h3 {
        color: white;
        font-family: $barlowMedium;
        font-size: 1em;
        padding: 0;
        margin: 0;
      }

      .icons-container {
        .icon {
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }
  }
}

.player3 {
  display: none;
  @media screen and (min-width: 1160px) {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    max-height: 85vh;
  }
}

.input-box {
  position: relative;
  height: 270px;
  width: 95%;
  margin: auto;
  max-width: 100%;
  background: white;
  margin-top: 140px;
  padding: 0 10px;

  @media screen and (min-width: 530px) {
    width: 85%;
  }

  @media screen and (min-width: $mobile) {
    height: 210px;
    margin-right: -190px;
    margin-top: 230px;
    max-width: 400px;
  }

  .opozorilo-container {
    padding: 0;
    margin-top: 25px;

    @media screen and (min-width: $mobile) {
      padding-right: 75px;
      margin-top: 45px;
    }

    .opozorilo {
      @media screen and (min-width: $mobile) {
        color: white;
      }
    }
  }
}

.mobile-nobreak {
  margin-top: 10%;
  max-width: 90%;
  margin: auto;

  @media screen and (min-width: $mobile) {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin-top: none;
  }
  @media screen and (min-width: 950px) {
    padding-left: 150px;
  }
  @media screen and (min-width: 1160px) {
    padding: 0;
    padding-right: 210px;
  }
}

.visible-field {
  z-index: 4;
  position: relative;
  @media screen and (min-width: $mobile) {
    margin-right: 85px;
  }

  .line {
    height: 1px;
    width: 70%;
    padding: 0;
    margin: 0 auto;
    margin-top: -24px;
  }

  .custom-input {
    border: 0;
    text-align: center;
    margin-top: 30px;
    font-family: $barlowBold;
    color: $darkBlue;
    font-size: 1.4em;
    text-transform: uppercase;
    max-width: 100%;
  }

  .small {
    font-size: 0.8em;
    line-height: 1.2;
    margin-bottom: 20px;
    cursor: default;
  }

  .btn-yellow {
    font-size: 1em;
    padding: 0.4em 3em;
    z-index: 1;
    margin: 0;
  }
}

.custom-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chair-container {
  position: relative;
  max-width: 540px;
  z-index: 3;
  margin-top: 0;
  margin-top: -100px;

  @media screen and (min-width: 400px) {
    margin-top: -150px;
  }

  @media screen and (min-width: 600px) {
    margin-top: -200px;
  }

  @media screen and (min-width: $mobile) {
    margin-top: 0;
  }

  .txt-container {
    position: absolute;
    top: 30%;
    left: 0;
    width: 100%;

    .start {
      max-width: 35px;
      width: 100%;
    }

    .name-container {
      padding: 0 24%;
      min-height: 45px;

      p {
        margin: 0;
        font-family: $barlowBold;
        color: white !important;
        font-size: 1.6em;
        text-transform: uppercase;
        line-height: 1.2em;
      }
    }

    .mastercard {
      max-width: 100%;
      width: 80px;

      @media screen and (min-width: 400px) {
        width: 130px;
      }
    }
  }

  .chair {
    margin: auto;
    width: 100%;
  }
}

.btn-yellow {
  font-size: 1.2rem;
  padding: 0.5rem 2rem;
  margin-top: 15px;

  @media screen and (min-width: $mobile) {
    font-size: 1.6rem;
    padding: 0.6rem 5rem;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #949494;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #949494;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #949494;
}
</style>