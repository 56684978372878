<template>
  <div v-if="showMsgBox" class="msg-box">
    <div class="txt-container">
      <p>{{text}}</p>
    </div>
    <div class="blue-container">
      <h3>{{title}}</h3>
      <div class="icons-container">
        <svg
          class="icon"
          v-on:click="$emit('scrollDown');"
          xmlns="http://www.w3.org/2000/svg"
          width="20.609"
          height="14.081"
          viewBox="0 0 20.609 14.081"
        >
          <path
            id="Path_1783"
            data-name="Path 1783"
            d="M0,0,9.34,11.321,18.681,0"
            transform="translate(0.964 0.796)"
            fill="none"
            stroke="#fff"
            stroke-miterlimit="10"
            stroke-width="2.5"
          />
        </svg>
        <svg
          class="icon"
          v-on:click="$emit('scrollUp');"
          xmlns="http://www.w3.org/2000/svg"
          width="20.609"
          height="14.081"
          viewBox="0 0 20.609 14.081"
        >
          <path
            id="Path_1783"
            data-name="Path 1783"
            d="M0,0,9.34,11.321,18.681,0"
            transform="translate(19.645 13.285) rotate(180)"
            fill="none"
            stroke="#fff"
            stroke-miterlimit="10"
            stroke-width="2.5"
          />
        </svg>

        <svg
          class="icon"
          v-on:click="closeMsgBox()"
          xmlns="http://www.w3.org/2000/svg"
          width="14.996"
          height="14.959"
          viewBox="0 0 14.996 14.959"
        >
          <g id="Group_1391" data-name="Group 1391" transform="translate(0.882 0.885)">
            <line
              id="Line_5"
              data-name="Line 5"
              x2="13.231"
              y2="13.189"
              transform="translate(0 0)"
              fill="none"
              stroke="#fff"
              stroke-width="2.5"
            />
            <line
              id="Line_6"
              data-name="Line 6"
              x1="13.231"
              y2="13.189"
              transform="translate(0 0)"
              fill="none"
              stroke="#fff"
              stroke-width="2.5"
            />
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "msgBox",

  props: ["text", "title"],

  data() {
    return {
      showMsgBox: true
    };
  },

  methods: {
    closeMsgBox() {
      this.showMsgBox = false;
    }
  }
};
</script>

<style scoped lang="scss">
.msg-box {
  display: none;
  position: relative;
  z-index: 7;

  @media screen and (min-width: 1200px) {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 350px;

    .icon:hover {
      #Path_1783,
      #Line_5,
      #Line_6 {
        stroke: $yellow;
        transition: all ease 0.2s;
      }
    }

    .txt-container {
      background-color: white;
      margin: 0;
      padding: 35px;
      border-top-left-radius: 23px;
      text-align: left;

      p {
        color: $darkBlue;
        font-family: $font-family;
        font-size: 0.9em;
        padding: 0;
        margin: 0;
      }
    }

    .blue-container {
      background-color: $darkBlue;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 35px;

      h3 {
        color: white;
        font-family: $barlowMedium;
        font-size: 1em;
        padding: 0;
        margin: 0;
      }

      .icons-container {
        .icon {
          margin: 0 5px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>