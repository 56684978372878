  
<template>
  <b-container class="podpri text-center" fluid>
    <div class="custom-container">
      <img class="player4" v-bind:src="require('@/assets/img/min/player4@2x-min.png')" />
      <div class="chair-container">
        <img
          class="partial-chair"
          v-bind:src="require('@/assets/img/min/chair-partial@2x-min.png')"
          alt
        />
        <div class="txt-container">
          <img class="stars" v-bind:src="require('@/assets/img/stars.svg')" />
          <h2>{{name}}</h2>
          <img class="mastercard" v-bind:src="require('@/assets/img/mastercard.svg')" alt />
        </div>
      </div>
      <div class="white-container">
        <h1>PODPRI NAS KOT:</h1>
        <br />
        <b-row>
          <b-col md class="yellow-box">
            <img class="icon" v-bind:src="require('@/assets/img/icon1.svg')" alt />
            <h2>POSAMEZNIK</h2>
            <p>
              Podpri nas kot posameznik za
              <span class="nobreak">54 €/leto.</span>
            </p>
            <a
              v-bind:href="'https://shop.rk-celje.si/collections/sedez/products/sedez-posameznik' + '?ime=' + this.name"
            >
              <b-btn pill variant="yellow" class="blue-bg hoverWhite white-txt">IZBERI</b-btn>
            </a>
          </b-col>
          <b-col md class="blue-box">
            <img class="icon" v-bind:src="require('@/assets/img/icon2.svg')" alt />
            <h2>PODJETJE</h2>
            <p>
              Spodbudite nas kot podjetje za
              <span class="nobreak">120 €/leto.</span>
            </p>
            <a
              v-bind:href="'https://shop.rk-celje.si/collections/sedez/products/sedez-podjetje' + '?ime=' + this.name"
            >
              <b-btn class="hoverWhite" pill variant="yellow">IZBERI</b-btn>
            </a>
          </b-col>
        </b-row>
        <br />
        <div class="txt-container">
          <p>Vaš sedež bomo pozicionirali naključno v Dvorani Zlatorog. Lokacijo sedeža vam bomo poslali na elektronski naslov.</p>
          <p>Pomembno opozorilo: z zakupom vašega sedeža ne zakupite tudi vstopnic za tekme. Gesta je namenjena dodatni podpori Rokometnega kluba Celje Pivovarna Laško. Zakup velja za obdobje enega leta.</p>
        </div>
      </div>
    </div>
    <footerSpodaj />
  </b-container>
</template>

<script>
import footerSpodaj from "@/components/footerSpodaj";

export default {
  name: "section3",

  components: {
    footerSpodaj
  },

  props: ["name"]
};
</script>

<style scoped lang="scss">
.podpri {
  position: relative;
  background: $bg3;
  background-size: cover;
  background-position: center;
  font-size: 12px;
  overflow: hidden;

  @media screen and (min-width: $mobile) {
    font-size: 16px;
    min-height: 100vh;
  }
}

.custom-container {
  position: relative;
  padding-top: 15vh;

  @media screen and (min-width: $mobile) {
    min-height: 85vh;
  }
}

.chair-container {
  position: relative;
  z-index: 3;

  .partial-chair {
    width: 100%;
    max-width: 800px;
    min-height: 100px;
  }

  .txt-container {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 18%;
    left: 0;
    padding: 0 25%;

    .stars {
      width: 100%;
      max-width: 30px;

      @media screen and (min-width: 530px) {
        top: 30%;
        max-width: 50px;
      }
    }

    h2 {
      font-family: $barlowBold;
      color: white;
      font-size: 1.8em;
      text-transform: uppercase;

      @media screen and (min-width: $mobile) {
        font-size: 2.1em;
      }
    }

    .mastercard {
      width: 70%;
      max-width: 130px;
    }
  }
}

.white-container {
  position: relative;
  width: 95%;
  height: 100%;
  background-color: white;
  margin: auto;
  padding: 3% 10% 2%;
  z-index: 3;

  @media screen and (min-width: 530px) {
    width: 80%;
  }

  @media screen and (min-width: $mobile) {
    max-width: 1000px;
    width: 60%;
    padding: 3% 5% 10%;
  }

  @media screen and (min-width: 1300px) {
    width: 90%;
    max-width: 900px;
  }

  @media screen and (min-width: 1640px) {
    width: 90%;
    max-width: 1100px;
  }

  h1 {
    font-family: $barlowBold;
    font-size: 2.4em;
    @media screen and (min-width: $mobile) {
      font-size: 3.2em;
    }
  }

  h2 {
    font-family: $barlowBold;
    font-size: 1.8em;

    @media screen and (min-width: 1100px) {
      font-size: 2.4em;
    }
  }

  p {
    font-family: $font-family;
    font-size: 1.3em;

    @media screen and (min-width: $mobile) {
      font-size: 0.8em;
    }
  }

  .yellow-box {
    background-color: $yellow;
    padding: 5%;

    .icon {
      padding-bottom: 20px;
    }

    @media screen and (max-width: $mobile) {
      margin-bottom: 5%;
    }

    @media screen and (min-width: $mobile) {
      margin-right: 2%;
    }
  }

  .blue-box {
    background-color: $darkBlue;
    padding: 5%;

    h2 {
      color: $yellow;
    }

    p {
      color: white;

      * {
        color: white !important;
      }
    }

    .icon {
      padding-bottom: 20px;
    }

    @media screen and (min-width: $mobile) {
      margin-left: 2%;
    }
  }

  .txt-container {
    width: 100%;
    max-width: 440px;
    margin: auto;
  }
}

.btn-yellow {
  font-size: 1.2rem;
  padding: 0.5rem 3rem;
  margin-top: 15px;

  @media screen and (min-width: $mobile) {
    padding: 0.4rem 2.5rem;
  }
  @media screen and (min-width: 1100px) {
    font-size: 1.3rem;
    padding: 0.55rem 4rem;
  }
}

.player4 {
  display: none;
  @media screen and (min-width: 1300px) {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    max-height: 85vh;
  }
}
</style>