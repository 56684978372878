<template>
  <b-container class="pridruzi text-center" fluid>
    <img
      id="playerImg"
      class="player2 animate__animated animate__slow"
      v-bind:src="require('@/assets/img/min/player2@2x-min.png')"
    />
    <div class="center-container">
      <div class="custom-container">
        <div class="chair-container">
          <img class="chair-img" v-bind:src="require('@/assets/img/chair_p1.png')" />
        </div>
        <div class="text-container">
          <div class="noflex-container">
            <img
              id="txtImg"
              class="text-image animate__animated animate__slow"
              v-bind:src="require('@/assets/img/text@2x-min.png')"
            />
            <br />
            <b-btn
              id="pridruzi-btn"
              class="hoverWhite"
              pill
              variant="yellowBig"
              v-on:click="$emit('scrollDown');"
            >ZAKUPI SVOJ SEDEŽ</b-btn>
          </div>
        </div>
      </div>
    </div>

    <msgBox
      text="Podpri razvoj našega kluba in se vpiši med legende. Ostanimo skupaj!"
      title="PRIDRUŽI SE NAM"
      @scrollDown="$emit('scrollDown');"
    ></msgBox>

    <scrollIcon></scrollIcon>
  </b-container>
</template>

<script>
import msgBox from "@/components/msgBox";
import scrollIcon from "@/components/scrollIcon";

export default {
  name: "pridruzi",

  props: ["isModalVisible"],

  components: {
    msgBox,
    scrollIcon
  },

  watch: {
    isModalVisible: function() {
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );

      var txt = document.getElementById("txtImg");
      txt.classList.add("animate__fadeIn");

      if (!this.isModalVisible && vw > 1200) {
        var img = document.getElementById("playerImg");
        img.style.display = "block";
        img.classList.add("animate__fadeInUp");
      }
    }
  }
};
</script>

<style scoped lang="scss">
.pridruzi {
  position: relative;
  background: $bg2;
  background-size: cover;
  background-position: center;
  height: 100vh;
  max-height: 100vh;
  font-size: 16px;
  padding: 0 !important;
  margin: 0 !important;
}

.center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.custom-container {
  position: relative;
  max-width: 1000px;
  width: 100%;
  height: 80vh;
  margin: auto;
  padding: 0;

  .text-container {
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 1em;

    .text-image {
      max-width: 100%;
      width: auto;
      max-height: 75vh;
      height: auto;
      margin-bottom: 0px;
    }

    h1 {
      color: $yellow;
      font-family: $barlowBold;
      text-shadow: 0 0 10em rgba(0, 0, 0, 0.5);
      letter-spacing: 0.22em;
      font-size: 4em;

      @media screen and (min-width: 550px) {
        font-size: 6em;
      }

      @media screen and (min-width: $mobile) {
        font-size: 10em;
      }

      @media screen and (min-width: 1420px) {
        font-size: 15em;
      }
    }

    h2 {
      color: white;
      font-family: $barlowBold;
      letter-spacing: 0.2em;
      text-shadow: 0 0 1em black;
      font-size: 2.8em;

      @media screen and (min-width: 550px) {
        font-size: 4em;
      }

      @media screen and (min-width: $mobile) {
        font-size: 5.8em;
      }

      @media screen and (min-width: 1420px) {
        font-size: 7em;
      }
    }

    h3 {
      color: white;
      font-family: $barlowMedium;
      letter-spacing: 0.25em;
      font-size: 2em;

      @media screen and (min-width: 550px) {
        font-size: 2.3em;
      }

      @media screen and (min-width: $mobile) {
        font-size: 2.8em;
      }

      @media screen and (min-width: 1420px) {
        font-size: 3em;
      }
    }
  }

  .chair-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    .chair-img {
      max-width: 100%;

      @media screen and (min-width: $mobile) {
        max-width: 70%;
      }
    }
  }

  .bottom-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    h4 {
      color: $yellow;
      font-family: $barlowMedium;
      font-size: 1.5em;
      letter-spacing: 0.5em;
      -webkit-text-stroke: 1px black;

      @media screen and (min-width: $mobile) {
        font-size: 2em;
        letter-spacing: 1em;
      }
    }
    @media screen and (min-width: 1650px) {
      left: 150px;
    }
  }
}

.player2 {
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  max-height: 90vh;
  max-width: 100%;
  z-index: 4;

  @media screen and (min-width: 1650px) {
    display: none;
  }
}

.btn-yellow {
  position: relative;
  z-index: 1;
  font-size: 1.2rem;
  padding: 0.4rem 2rem;

  @media screen and (min-width: 1120px) {
    font-size: 1.6rem;
    padding: 0.6rem 5rem;
  }
}
</style>